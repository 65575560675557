import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PDF from './../PDF/main.js'
import { PDFViewer } from '@react-pdf/renderer';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Modal from '@mui/material/Modal';

const theme = createTheme();
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: "100%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


export default function SignIn() {
    const [date, setDate] = React.useState(new Date())
    const [module, setModule] = React.useState("")
    const [category, setCategory] = React.useState("")
    const [rate, setRate] = React.useState(0)
    const [quotation, setQuotation] = React.useState(false)
    const [details, setDetails] = React.useState({})
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        var dueDate=new Date(date.getTime())
        dueDate.setDate(date.getDate() + 5)

        var obj = {
            name: data.get('name'),
            address: data.get('address'),
            date: date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear(),
            id: data.get('id'),
            module: module,
            category: category,
            width: data.get('width'),
            height:  data.get('height'),
            rate:  data.get('rate'),
            discount: data.get('discount'),
            screen: data.get('screen'),
            dueDate: dueDate.getDate()+"/"+(dueDate.getMonth()+1)+"/"+dueDate.getFullYear(),
        }
       
        if(obj.name=="" || obj.address==""  || obj.id=="" || obj.module=="" || obj.category=="" || obj.width=="" || obj.height=="" || obj.rate=="" || obj.rate=="0" || obj.screen=="0" || obj.screen=="" || obj.discount=="0" || obj.discount==""){
            alert("Please provide complete information!")
            return
        }else{
            setDetails(obj)
            setQuotation(true)
        }
        
    };
    const  numberWithCommas=(x)=>{
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <ThemeProvider theme={theme}>

            <Container component="main" maxWidth="md">
                <CssBaseline />
                <Modal
                                        open={quotation}
                                        onClose={() => setQuotation(false)}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={style}>
                                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                                Quotation
                                            </Typography>
                                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                            <PDFViewer width={"100%"} height="1000" className="app" >
                                                <PDF details={details} />
                                            </PDFViewer>
                                            </Typography>
                                        </Box>
                                    </Modal>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Create Quotation
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate  style={{width:"100%"}}>
                        <Grid container>
                            <Grid item={5} >
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    style={{width:"20em"}}
                                    id="name"
                                    label="Client Business Name"
                                    name="name"
                                    autoComplete="name"
                                    autoFocus
                                />
                            </Grid>
                            <Grid item={5} >
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    style={{width:"30em", marginLeft:"1em"}}
                                    name="address"
                                    label="Client Address"
                                    type="text"
                                    id="address"
                                    autoComplete="current-address"
                                />
                            </Grid>
                            <Grid item={2} 
                                        style={{
                                            marginTop:15
                                        }}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                >
                                    <DatePicker inputFormat="dd/MM/yyyy"

                                        value={date}
                                        label={"Date"}
                                        onChange={(date) => {
                                            setDate(date)
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />

                                </LocalizationProvider>
                            </Grid>
                            <Grid item={5}    >
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    style={{width:"20em", marginLeft:5}}
                                    name="id"
                                    label="Quotation No#"
                                    type="text"
                                    id="id"
                                    autoComplete="current-address"
                                />
                            </Grid>
                            <Grid item={5} style={{
                                            marginTop:5
                                        }}>
                            <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-filled-label">Module</InputLabel>
                            <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={module}
                            onChange={(event)=>{
                                setModule(event.target.value);
                            }}
                            >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={"P1.5"}>P1.5</MenuItem>
                            <MenuItem value={"P1.8"}>P1.8</MenuItem>
                            <MenuItem value={"P2.5"}>P2.5</MenuItem>
                            <MenuItem value={"P4"}>P4</MenuItem>
                            <MenuItem value={"P5"}>P5</MenuItem>
                            <MenuItem value={"P6"}>P6</MenuItem>
                            <MenuItem value={"P8"}>P8</MenuItem>
                            <MenuItem value={"P10"}>P10</MenuItem>
                            </Select>
                        </FormControl>
                            </Grid>
                            <Grid item={5} style={{
                                            marginTop:5
                                        }}>
                            <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-filled-label">Category</InputLabel>
                            <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={category}
                            onChange={(event)=>{
                                setCategory(event.target.value);
                            }}
                            >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={"INDOOR"}>INDOOR</MenuItem>
                            <MenuItem value={"OUTDOOR"}>OUTDOOR</MenuItem>
                            </Select>
                        </FormControl>
                            </Grid>


                            <Grid item={2}    >
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    style={{width:"7em", paddingRight:5}}
                                    name="width"
                                    label="Width"
                                    type="number"
                                    id="width"
                                    autoComplete="current-address"
                                />
                                
                            </Grid>
                            <b style={{
                                marginTop:30
                            }}>X</b>
                            <Grid item={2}    >
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    style={{width:"7em", paddingRight:5}}
                                    name="height"
                                    label="Height"
                                    type="number"
                                    id="height"
                                    autoComplete="current-address"
                                />
                            </Grid>
                            <Grid item={2}    >
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    style={{width:"10em", paddingRight:5}}
                                    name="screen"
                                    label="No. of Screens"
                                    type="number"
                                    id="screen"
                                    autoComplete="current-address"
                                />
                            </Grid>
                            
                            <Grid item={5}    >
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    style={{width:"20em"}}
                                    name="discount"
                                    label="Discount per sq.feet"
                                    type="number"
                                    id="discount"
                                    autoComplete="current-address"
                                />
                            </Grid>
                            <Grid item={5}    >
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    value={rate}
                                    onChange={(e)=>{setRate(e.target.value)}}
                                    style={{width:"20em", marginLeft:5}}
                                    name="rate"
                                    label={"Rate per Square Feet ("+numberWithCommas(rate)+")"}
                                    type="number"
                                    id="rate"
                                    autoComplete="current-address"
                                />
                            </Grid>

                        </Grid>


                        <Button
                            type="submit"
                            fullWidth
                            style={{
                                background:"purple",
                                color:'white'
                            }}
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Create
                        </Button>

                    </Box>
                </Box>
            </Container>

        </ThemeProvider>
    );
}